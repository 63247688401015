<template>
  <div ref="body">
    <custom-header active="global" :subActive="current"></custom-header>

    <div class="content-container">
      <div class="left-bar ">
        <div class="serch-bar flex">
          <div class="serch-box flex align-center">
            <van-icon name="search" />
            <input type="search" v-model="keyword" placeholder="search content" @keyup.enter="onSearch">
          </div>
          <button class="search-btn" @click="onSearch">Search</button>
        </div>
        <div class="title text-bold">Categories</div>
        
        <!-- 品类 -->
        <div class="main-menu">
          <div class="row">
            <div :class="['theme', !categoryId ? 'active' : '']" @click="categoryChange('')">
              <div class="theme-item">All Categories</div>
            </div>
            <template v-for="(item, idx) in categorys">
              <div :key="idx" :class="['theme', categoryId == item.categoryId ? 'active' : '']" @click="categoryChange(item.categoryId)">
                <div class="theme-item">{{item.categoryName}}</div>
              </div>
            </template>
          </div>
        </div>
        
        <div class="pavilion-box1">
          
          <!-- 移动端 展馆 -->
          <!-- <div class="row">
            <div :class="['item', !pavilionId ? 'active' : '']" @click="pavilionChange('')">
              <div class="option">All</div>
            </div>
            <div v-for="(item) in pavilions" :key="item.pavilionId" :class="['item', pavilionId == item.pavilionId ? 'active' : '']" @click="pavilionChange(item.pavilionId)">
              <div class="option">{{item.pavilionName}} {{item.pavilionDesc}}</div>
            </div>
          </div> -->
          
          <!-- 移动端 年份 -->
          <div class="row">
            <div :class="['item', !exh ? 'active' : '']" @click="yearChange('')">
              <div class="option">All</div>
            </div>
            <div :class="['item', exh == '39' ? 'active' : '']" @click="yearChange('39')">
              <div class="option">2024</div>
            </div>
            <div :class="['item', exh == '40' ? 'active' : '']" @click="yearChange('40')">
              <div class="option">2025</div>
            </div>
          </div>
          
        </div>
      </div>
      
      <div class="exh-content">
        
        <!-- pc端 展馆 -->
        <!-- <div class="pavilion-box2 flex flex-wrap">
          <div :class="['item', !pavilionId ? 'active' : '']" @click="pavilionChange('')">
            <div class="option">All</div>
          </div>
          <div v-for="(item) in pavilions" :key="item.pavilionId" :class="['item', pavilionId == item.pavilionId ? 'active' : '']" @click="pavilionChange(item.pavilionId)">
            <div class="option">{{item.pavilionName}} {{item.pavilionDesc}}</div>
          </div>
        </div> -->
        
        <!-- pc端 年份 -->
        <div class="pavilion-box2 flex flex-wrap">
          <div :class="['item', !exh ? 'active' : '']" @click="yearChange('')">
            <div class="option">All</div>
          </div>
          <div :class="['item', exh == '39' ? 'active' : '']" @click="yearChange('39')">
            <div class="option">2024</div>
          </div>
          <div :class="['item', exh == '40' ? 'active' : '']" @click="yearChange('40')">
            <div class="option">2025</div>
          </div>
        </div>
        
        <div class="list">
          <van-empty v-if="exhibitors.length === 0" description="" />
          <template v-else>
            <div class="flex flex-wrap">            
              <div class="exhibitor" v-for="(item, i) in exhibitors" :key="i" @click="showDetail(item)">
                <div class="exhibitor-cover">
                  <!-- <img :src="item.banners" :alt="item.brandNameEn || item.brandName" class="fill block" style="object-fit: cover;"> -->
                  <!-- <img :src="item.banners" :alt="item.brandNameEn || item.brandName" class="fill block" style="object-fit: scale-down;"> -->
                  <img :src="item.banners" :alt="item.brandNameEn || item.brandName" class="fill block">
                </div>
                <div>
                  <div class="name text-bold">{{ item.brandNameEn || item.brandName }}</div>
                  <!-- <template>
                    <div class="intro">主营品类：{{ item.categories }}</div>
                    <div class="intro">主要客群：{{ item.mainAudience }}</div>
                    <div class="tagBox">
                      <div v-if="item.starName" class="tagBox-item tagBox-item1">{{ item.starName }}</div>
                      <div v-if="item.recordStatus" class="tagBox-item tagBox-item2">原创数字备案</div>
                    </div>
                  </template> -->
                </div>
              </div>
            </div>
            <div class="pagination-box flex justify-center">
              <el-pagination background layout="prev, pager, next" :hide-on-single-page="pages <= 1" :page-size="pageSize" :total="total" @current-change="handleCurrentChange"></el-pagination>
            </div>
          </template>
        </div>
      </div>
    </div>
    
    <van-overlay :show="show" z-index="10"  @click="closeDetail">
      <div class="" @click.stop>
        <div class="exh-box bg-white scrollBar">
          <div class="exh-title text-bold text-center">{{ exhInfo.brandNameEn || exhInfo.brandName || exhInfo.companyNameEn || exhInfo.companyName }}</div>
          <div class="">
            <img :src="exhInfo.banners" class="exh-banner" />
          </div>
          <!-- <div v-if="exhInfo.positionNumber" class="position">展位号: {{exhInfo.positionNumber}}</div> -->
          <div v-if="exhInfo.phone" class="phone"><span class="text-bold">Contact No:</span> 0086 {{exhInfo.phone}}</div>
          <div v-if="exhInfo.mailbox" class="phone"><span class="text-bold">Email:</span> {{exhInfo.mailbox}}</div>
          <div v-if="exhInfo.qrCode" class="contact">
            <img :src="exhInfo.qrCode" class="exh-qrcode" />
            <div class="desc text-center">Company QR Code</div>
          </div>
          <div class="mail-box">
            <div class="noties">If you have any enquiry, please do not hesitate to contact us by:</div>
            <div><a href="mailto:business-cooperation.szfa@outlook.com">business-cooperation.szfa@outlook.com</a></div>
            <div><a href="mailto:business-cooperation@szfa.com">business-cooperation@szfa.com</a></div>
          </div>
          <!-- <div class="desc">{{exhInfo.companyDesc}}</div> -->
        </div>
      </div>
    </van-overlay>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
  </div>
</template>

<script>
import { Swipe, SwipeItem, Icon, Empty, Search, Overlay } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';
import { getPavilionList, getCategoryList, getExhibitorByCategory, getExhibitorDetail } from '@/api/index.js'

export default {
  // name: '展商列表',
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter, 'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Empty.name]: Empty,
    [Search .name]: Search ,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Overlay.name]: Overlay,
  },
  mixins: [commonMixin],
  metaInfo: {
    title: 'Shenzhen China World Exhibition Week: All Exhibitor Categories', // set a title
    meta: [
      { name: 'keyWords', content: 'Furniture Fair,Home Design,Furniture Exhibitor,Home Furnishing Forum,Design Week,Home Design Week' },
      { name: 'description', content: 'Shenzhen China International Furniture Exhibition & Creative Week 2025: Check Out the Details of the Participating Exhibitors Category-wise Here!  '},
    ],
  },
  data() {
    return {
      current: 'exhibitor',
      pageNo: 1,
      pageSize: 20,
      total: 0,
      pages: 1,
      show: false,
      keyword: '',
      pavilionId: '', // 展馆id
      categoryId: '', // 品类id
      categorys: [],
      pavilions: [],
      exhibitors: [],
      pavilionIdList: [],
      exhInfo: {},
      exh: '',
    }
  },
  created() {
    let {pavilionId = '', categoryId = ''} = this.$route.query;
    this.pavilionId = pavilionId; // 展馆id
    this.categoryId = categoryId; // 品类id
    
    this.getCategorys(); // 获取品类列表
    // this.getPavilions(); // 获取展馆列表
    // this.getExhibitors(); // 获取展商列表
    
    if (this.categoryId) {
      this.getPavilions(true);
    } else {
      this.getPavilions(); // 获取展馆列表
      this.getExhibitors(); // 获取展商列表
    }
    
  },
  methods: {
    // 年份切换
    yearChange(exh) {
      if (exh == this.exh) return;
      
      this.pageNo = 1;
      this.exh = exh;
      this.getExhibitors();
    },
    // 展馆切换
    pavilionChange(pavilionId) {
      if (pavilionId == this.pavilionId) return;
      
      // this.categoryId = '';
      this.pavilionIdList = pavilionId ? pavilionId : (this.pavilions.map(e => e.pavilionId).join(','));
      
      this.pageNo = 1;
      this.pavilionId = pavilionId;
      // this.getCategorys();
      this.getExhibitors();
    },
    // 品类切换
    categoryChange(categoryId) {
      if (categoryId == this.categoryId) return;
      
      this.pavilionId = '';
      
      this.categoryId = categoryId;
      this.pageNo = 1;
      this.getPavilions(true);
      // this.getExhibitors();
    },
    // 获取展馆列表
    getPavilions(isLoadExhibitors = false) {
      getPavilionList(this.categoryId).then(res => {
        this.pavilions = res.resultData || [];
        if (isLoadExhibitors) {
          this.pavilionIdList = this.pavilions.map(e => e.pavilionId).join(',');
          this.getExhibitors()
        }
      })
    },
    // 获取品类列表
    getCategorys() {
      let params = {
        pavilionId: this.pavilionId,
        displayStatus: 1,
      }
      getCategoryList(params).then(res => {
        if (this.pavilionId) {
          let tmp = [];
          (res.resultData || []).map(e => {
            tmp.push(...e.nodes); // e.nodes: [{}, ...]
          })
          this.categorys = tmp; // 填充二级品类
        } else{
          this.categorys = res.resultData || []; // 填充一级品类
        }
        
      })
    },
    // 获取展商列表
    getExhibitors() {
      let params = {
        keyword: this.keyword,
        pageNo: this.pageNo,
        pageSize: this.pageSize,
        // categoryId: this.categoryId,
        // pavilionId: this.pavilionId,
        pavilionIdList: this.pavilionIdList,
        exh: this.exh,
      };
      getExhibitorByCategory(params).then(res => {
        let {records = [], total = 0, pages = 0 } = res.resultData;
        this.total = total;
        this.pages = pages;
        this.exhibitors = records.map((v) => {
          v.mainAudience = v.mainAudience.replaceAll(';', '；');
          return v;
        });
      })
    },
    showDetail(row) {
      getExhibitorDetail(row.exhibitorId).then(res => {
        let exhInfo = res.resultData || {};
        this.exhInfo = exhInfo;
        this.show = true;
      })
    },
    closeDetail() {
      this.exhInfo = {}
      this.show = false;
    },
    onSearch() {
      this.pavilionId = ''; // 展馆id
      this.categoryId = ''; // 品类id
      this.pageNo = 1;
      this.getExhibitors();
    },
    // 翻页
    handleCurrentChange(val) {
      this.pageNo = val;
      this.getExhibitors();
    },
  },
}
</script>

<style scoped lang="less">
// 移动端
@media screen and (max-width: 1023px) {
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
  }
  .content-container {
    width: 375px;
    margin: 0 auto 20px;
    
    .left-bar {
      width: 100%;
      padding: 0 10px 10px;
      
      .serch-bar {
        margin-bottom: 10px;
        
        .search-btn {
          background-color: #060211;
          color: #fff;
          border: none;
          width: 55px;
          height: 30px;
          border-radius: 4px;
          padding: 0;
          font-size: 13px;
        }
        
        .serch-box {
          width: 290px;
          height: 30px;
          padding: 0 10px;
          margin-right: 10px;
          box-sizing: border-box;
          background-color: #F7F8FA;
          border-radius: 4px;
          overflow: hidden;
          
          input {
            width: 100%;
            border: none;
            background-color: transparent;
            font-size: 13px;
            padding-left: 5px;
          }
        }
    
        .van-icon-search {
          font-size: 16px;
          font-weight: bold;
        }
      }
      
      .title {
        display: none;
      }
      
      .main-menu {
        width: 100%;
        overflow-x: auto;
        
        &::-webkit-scrollbar {
          height: 0;
        }
        .row {
          width: auto;
          white-space: nowrap; 
          
          .theme {
            display: inline-block;
            width: auto;
            text-align: center;
            border-radius: 4px;
            text-align: center;
            background-color: #fff;
            margin-right: 10px;
            
            &.active {
              .theme-item {
                background-color: #060211;
                color: #fff;
              }
            }
            .theme-item {
              line-height: 30px;
              font-weight: normal;
              font-size: 12px;
              padding: 0 10px;
              border-radius: 4Px;
            }
            
          }
        }
        
      }
      
    }
    .pavilion-box2 {
      display: none;
    }
    .pavilion-box1 {
      margin: 10px 0;
      width: 100%;
      overflow-x: auto;
      
      &::-webkit-scrollbar {
        height: 0;
      }
      
      .row {
        width: auto;
        white-space: nowrap; 
        
        .item {
          display: inline-block;
          width: auto;
          text-align: center;
          border-radius: 4px;
          text-align: center;
          background-color: #fff;
          margin-right: 10px;
          
          &.active {
            .option {
              background-color: #060211;
              color: #fff;
            }
          }
          .option {
            line-height: 30px;
            font-weight: normal;
            font-size: 12px;
            padding: 0 10px;
            border-radius: 4Px;
          }
          
        }
      }
      
    }

    .exh-content {
      width: 375px;
      
      .list {
        width: 100%;
        padding: 0 5px;
        
        /deep/ .van-empty__image {
          width: 100%;
          height: auto;
        }
        
        .exhibitor {
          width: 172px;
          margin: 0 5px 8px;
          padding-bottom: 2px;
          
          .exhibitor-cover {
            display: block;
            // width: 172px;
            // height: 172px;
            width: 172px;
            height: 100px;
            border-radius: 4px;
            overflow: hidden;
          }
          
          .name {
            width: 100%;
            padding: 5px;
            font-size: 14px;
            line-height: 2em;
          }
          
          .intro {
            font-size: 12px;
            padding: 0 5px;
            margin-bottom: 5px;
          }
          
          .tagBox {
            display: flex;
            padding: 0 2px;
            
            &-item {
              margin-right: 5px;
              padding: 5px 6px;
              border-radius: 4px;
              font-size: 11px;
              color: #fefefe;
              text-align: center;
              &:last-child {
                margin-right: 0;
              }
            }
            &-item1 {
              background: #c29441;
            }
            &-item2 {
              background-color: #22418c;
            }
          }
        }
        
      }
      
      .pagination-box {
        margin-top: 20px;
        
        /deep/ .el-pagination.is-background {
          
          .btn-next .el-icon, 
          .btn-prev .el-icon {
            font-size: 14px;
            height: 24px;
            line-height: 24px;
          }
          
          .btn-next,
          .btn-prev, 
          .el-pager li {
            margin: 0 1px;
            color: #606266;
            min-width: 24px;
            border-radius: 4px;
            padding: 0 5px;
            font-size: 14px;
            height: 24px;
            line-height: 24px;
          }
          
          .el-pager li:not(.disabled).active {
            background-color: #409EFF;
            color: #FFF;
          }
          
          .el-pager .more::before {
            line-height: 24px;
          }
        }
      
      }
      
    }
  
  }
  
  .exh-box {
    position: absolute;
    z-index: 11;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 85vh;
    width: 360px;
    padding: 10px;
    border-radius: 4px;
    overflow: auto;
    
    .exh-title {
      font-size: 16px;
    }
    
    .position {
      color: #aaa;
      font-size: 12px;
      margin-top: 5px;
    }
    
    .exh-banner {
      display: block;
      width: 100%;
      height: auto;
      margin: 10px auto;
      border-radius: 4px;
    }
    
    .phone {
      font-size: 14px
    }
    .exh-qrcode {
      display: block;
      width: 150px;
      height: auto;
      margin: 10px auto 0;
    }
    
    .mail-box {
      margin: 10px 0;
      
      .noties {
        font-size: 14px;
      }
      
      a {
        font-size: 14px;
        color: #409EFF;
      }
    }
    
    .desc {
      font-size: 13px!important;
      line-height: 1.6em;
    }
  }
  
}

// PC
@media screen and (min-width: 1024px) {
  .banner-container {
      width: 100%;
      
      .banner {
        width: 100%;
        height: 100%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 100% auto;
      }
    }
    .content-container {
      display: flex;
      width: 320px;
      margin: 20px auto ;
      
      .left-bar {
        width: 80px;
        padding: 0 10px 10px;
        box-sizing: border-box;
        
        .serch-bar {
          margin-bottom: 10px;
          
          .search-btn {
            background-color: #060211;
            color: #fff;
            border: none;
            width: 16px;
            height: 8px;
            border-radius: 1px;
            padding: 0;
            font-size: 3px;
          }
          
          .serch-box {
            width: 54px;
            height: 8px;
            padding: 2px 3px;
            margin-right: 2px;
            box-sizing: border-box;
            background-color: #F7F8FA;
            border-radius: 1px;
            overflow: hidden;
            
            input {
              width: 100%;
              border: none;
              background-color: transparent;
              font-size: 3px;
              padding-left: 2px;
            }
          }
  
          .van-icon-search {
            font-size: 4.8px;
            font-weight: bold;
          }
        }
        
        .title {
          font-size: 6px;
          font-weight: bold;
          text-align: center;
          margin-bottom: 10px;
        }
        
        .theme {
          width: 60px;
          margin: 2px 0;
          cursor: pointer;
          text-align: center;
          border-radius: 1px;
          border: 0.5px solid #fff;
          line-height: 12px;
          
          &.active,
          &:hover {
            border-color: #060211;
          }
          .theme-item {
            font-weight: normal;
            font-size: 3.5px;
            font-family: 'Poppins-Medium';
          }
          
        }
      }
      
      .pavilion-box1 {
        display: none;
      }
      .exh-content {
        width: 230px;
        
        .pavilion-box2 {
          margin-bottom: 7px;
          
          .item {
            margin: 0 1px 1px;
            cursor: pointer;
            text-align: center;
            background-color: #fff;
            
            &.active {
              .option {
                background-color: #060211;
                color: #fff;
              }
            }
            .option {
              height: 10px;
              line-height: 10px;
              font-weight: normal;
              font-size: 3.6px;
              padding: 0 4px;
              border-radius: 3Px;
              
              &:hover {
                background-color: #060211;
                color: #fff;
              }
            }
            
          }
          
        }
      
        .list {
          
          /deep/ .van-empty__image {
            width: 40%;
            height: auto;
          }
        }
        
        .exhibitor {
          width: 53px;
          margin: 0 5px 6px 0;
          padding-bottom: 2px;
          border-radius: 1px;
          cursor: pointer;
          
          &:nth-child(4n) {
            margin-right: 0;
          }
          
          &:hover {
            box-shadow: 0 1px 2px rgba(0, 0, 0, .2);
            
            .exhibitor-cover {
              border-radius: 1px 1px 0 0;
            }
          }
          
          .exhibitor-cover {
            display: block;
            // width: 53px;
            // height: 53px;
            width: 53px;
            height: 30px;
            border-radius: 1px;
            overflow: hidden;
            margin-bottom: 2px;
          }
          
          .name {
            width: 100%;
            padding: 2px;
            margin-bottom: 1px;
            font-size: 3.6px;
          }
          
          .intro {
            font-size: 3.2px;
            padding: 0 2px;
            margin-bottom: 2px;
          }
          
          .tagBox {
            display: flex;
            padding: 0 2px;
            
            &-item {
              // max-width: 10em;
              margin-right: 2px;
              padding: 1px 2px;
              border-radius: 1px;
              font-size: 3px;
              color: #fefefe;
              text-align: center;
              &:last-child {
                margin-right: 0;
              }
            }
            &-item1 {
              background: #c29441;
            }
            &-item2 {
              background-color: #22418c;
            }
          }
        }
        
        /deep/ .el-pagination.is-background {
          
          .btn-next .el-icon, 
          .btn-prev .el-icon {
            font-size: 3.6px;
            font-weight: 700;
            height: 8px;
            line-height: 8px;
          }
          
          .btn-next,
          .btn-prev, 
          .el-pager li {
            margin: 0 1px;
            color: #606266;
            min-width: 8px;
            border-radius: 1px;
            padding: 0 1px;
            font-size: 3.6px;
            height: 8px;
            line-height: 8px;
          }
          
          .el-pager li:not(.disabled).active {
            background-color: #409EFF;
            color: #FFF;
          }
          
          .el-pager .more::before {
            line-height: 8px;
          }
        }
  
      }
    
    }
    
    .exh-box {
      position: absolute;
      z-index: 11;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 125px;
      // max-width: 800Px;
      // min-width: 500Px;
      max-height: 80vh;
      padding: 3px 5px;
      border-radius: 2px;
      overflow: auto;
      
      .exh-title {
        font-size: 5px;
      }
      
      .position {
        color: #aaa;
        font-size: 3.6px;
        margin-top: 1px;
      }
      .contact {
        font-size: 4px;
        margin-top: 1px;
      }
      
      .exh-banner {
        display: block;
        width: 70%;
        height: auto;
        margin: 2px auto;
        border-radius: 1px;
      }
      
      .phone {
        width: 70%;
        font-size: 4px;
        // text-align: center;
        margin: 0 auto;
      }
      
      .exh-qrcode {
        display: block;
        width: 40px;
        height: auto;
        margin: 0 auto;
      }
      
      .mail-box {
        margin: 3px 0;
        line-height: 1.2;
        
        .noties {
          font-size: 3.2px;
        }
        
        a {
          font-size: 3.2px;;
          color: #409EFF;
        }
      }
      .desc {
        font-size: 3.2px!important;
        line-height: 4.8px;
      }
    }
    
  .scrollBar {
    scrollbar-face-color: #fff;
    /*主色调*/
    scrollbar-highlight-color: #e6eaed;
    scrollbar-shadow-color: #e6eaed;
    /*描边颜色*/
    scrollbar-3dlight-color: #e6eaed;
    /*左侧边高光颜色*/
    scrollbar-arrow-color: transparent;
    /*小三角颜色*/
    scrollbar-track-color: #e6eaed;
    scrollbar-darkshadow-color: #DDD;
  }
  .scrollBar::-webkit-scrollbar {
    /*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
    width: 2px;
    height: 0;
    background-color: #A4A4A4;
    border-radius: 50%;
  }
  .scrollBar::-webkit-scrollbar-track {
    /*定义滚动条轨道*/
    background-color: #F1F1F1;
    border-radius: 1px;
  }
  .scrollBar::-webkit-scrollbar-thumb {
    /*定义滑块*/
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,0.3);
  }
  
  .scrollBar::-webkit-scrollbar-button:vertical  {
    background-color: #ddd;
  }
  
}

</style>
